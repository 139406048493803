import classNames from 'classnames';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AiOutlineRollback} from 'react-icons/ai';
import {Link, useParams} from 'react-router-dom';
import {useGetAdminCourse} from '../../../../api/courses/Courses';
import {useGetAllLanguages} from '../../../../api/localization/Localization';
import NarrowContent from '../../../../components/commonStructure/NarrowContent';
import CoursePageHeader from '../../../../components/coursePage/coursePageHeader/CoursePageHeader';
import LoadingEditorBlock from '../../../../components/editor/elements/common/LoadingBlock';
import ViewerAutoblock from '../../../../components/editor/service/autoBlocks/ViewerAutoblock';
import Button from '../../../../components/form/button/Button';
import FormButton from '../../../../components/form/button/FormButton';
import {useForm} from 'react-hook-form';
import {LocalizationDTO} from '../../../../api/localization/dto/LocalizationDTO';
import ListBox from '../../../../components/form/listbox/Listbox';

const CourseAdminPreview: React.FC = () => {
  const {courseId} = useParams<{courseId: string}>();
  const course = useGetAdminCourse(courseId || '');
  const [currentLanguage, setCurrentLanguage] = useState<string>();
  const commonTranslation = useTranslation('common');

  const languages = useGetAllLanguages({
    onSuccess: (data) => {
      setCurrentLanguage(data[0].id);
    },
  });

  const languageSelector = useForm<{language: LocalizationDTO}>();

  if (!languages.isSuccess || !course.isSuccess) return <LoadingEditorBlock />;
  return (
    <>
      <div className="absolute top-20 left-5 flex gap-2 z-50 w-1/2">
        <div className="flex w-full gap-2">
          <Link to="/admin/simulations" className="bg-white w-fit">
            <FormButton>
              <div className="flex justify-between items-center gap-2">
                <AiOutlineRollback /> Back
              </div>
            </FormButton>
          </Link>
          <ListBox
            className="grow"
            name="language"
            getValues={languageSelector.getValues}
            setValue={languageSelector.setValue}
            options={languages.data.map((el) => ({
              label: el.name,
              value: el,
            }))}
            onChange={(val) => setCurrentLanguage(val.id)}
          />
        </div>
      </div>
      <CoursePageHeader
        subject={commonTranslation.t(`CourseSubject.${course.data.subject}`)}
        device={commonTranslation.t(`CourseSection.${course.data.section}`)}
        duration={10}
        title={
          course.data.translations.find((x) => x.languageId == currentLanguage)
            ?.name || ''
        }
        background={course.data.backgroundImageId}
      />
      <NarrowContent>
        {course.data.translations.map((el) => (
          <div
            className={classNames(el.languageId != currentLanguage && 'hidden')}
            key={el.bodyId}
          >
            <ViewerAutoblock blocks={[JSON.parse(el.body)]} />
          </div>
        ))}
      </NarrowContent>
      <div className="my-12">
        <Link to="/request-demo">
          <Button className="mx-auto block mt-8">
            {commonTranslation.t('start')}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default CourseAdminPreview;
