import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

import {initReactI18next} from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import {API_REGION} from '../../services/flagProvider/FlagProvider';

i18n
  .use(detector)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./keys/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    }),
  )
  .init({
    lng: API_REGION === 'RU' ? 'ru' : undefined,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['subdomain', 'querystring', 'navigator', 'htmlTag'],
    },
  });
