import {AxiosError} from 'axios';
import {UseShortMutationOptions} from '../../helpers/ReactQuesyOptionBuilder';
import {ApiError} from '../basicTypes/ApiError';
import {ContactUsFormDTO} from './dto/ContactUsFormDTO';
import {useMutation} from '@tanstack/react-query';
import APIRequest from '../Api';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import {RequestDemoDTO} from './dto/RequestDemoDTO';

const ContactUsFormKey = '/SignupForm/ContactUsForm';

export const useContactUsForm = (
  options?: UseShortMutationOptions<
    void,
    AxiosError<ApiError>,
    ContactUsFormDTO
  >,
) => {
  return useMutation<void, AxiosError<ApiError>, ContactUsFormDTO>(
    [ContactUsFormKey],
    async (formInfo: ContactUsFormDTO): Promise<void> => {
      const {data} = await APIRequest.post(
        `/SignupForm/ContactUsForm`,
        formInfo,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};

export const useRequestDemoForm = (
  options?: UseShortMutationOptions<void, AxiosError<ApiError>, RequestDemoDTO>,
) => {
  return useMutation<void, AxiosError<ApiError>, RequestDemoDTO>(
    [ContactUsFormKey],
    async (formInfo: RequestDemoDTO): Promise<void> => {
      const {data} = await APIRequest.post(
        `/SignupForm/ContactUsForm`,
        formInfo,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
