import {Link} from 'react-router-dom';
import {useGetRandomCourses} from '../../api/courses/Courses';
import FullBlock from '../../components/common/fullBlock/FullBlock';
import CourseCard from '../../components/courses/CourseCard';
import Button from '../../components/form/button/Button';
import QuestionLine from '../../components/mainPage/faq/faq';
import HowItWorksElement from '../../components/mainPage/howItWorks/HowItWorks';
import './homePage.css';
import {useTranslation} from 'react-i18next';

const HomePage: React.FC = () => {
  const {t, i18n} = useTranslation('main');
  const rndCourses = useGetRandomCourses(i18n.language, 3);
  return (
    <>
      <section className="w-full hero flex flex-col items-center">
        <div className="flex relative w-full">
          <div className="relative flex flex-col w-full h-full z-10 overvideo-bg">
            <div className="px-8 lg:px-2 lg:ml-40 mb-4 md:mb-10 lg:mb-44">
              <h1 className="font-bold text-4xl lg:text-6xl text-left text-white mt-10 lg:mt-56">
                {t('title')}
              </h1>
              <hr className="mt-8 border border-solid border-white w-4/6" />
              <h3 className="mt-8 font-normal text-xl text-white">
                {t('subtitle')}
              </h3>
              <div className="flex flex-col items-center lg:flex-row mt-12 lg:mt-24">
                <Link
                  to="/request-demo"
                  className="mr-0 lg:mr-6 w-full lg:w-auto"
                >
                  <Button className="w-full">{t('request-a-demo')}</Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="absolute top-0 items-center w-full h-full overflow-hidden justify-center z-0">
            <video
              autoPlay
              loop
              muted
              className="block w-full h-full object-cover object-center"
            >
              <source src="/bgvideo2.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>

      <FullBlock header={t('how-it-works')} className="bg-gray-200">
        <div className="justify-between grid gap-y-4 lg:grid-cols-3 grid-cols-1 w-full">
          <HowItWorksElement number="1." title={t('subscribe-to-xreadylab')}>
            <p className="text-sm lg:text-base">
              {t('choose-simulations')}
              <Link to="/contacts" className="underline ml-1">
                {t('form')}
              </Link>
            </p>
          </HowItWorksElement>
          <HowItWorksElement number="2." title={t('book-a-date-for-vr-setup')}>
            <p className="text-sm lg:text-base">{t('we-train-your-employ')}</p>
          </HowItWorksElement>
          <HowItWorksElement number="3." title={t('technical-support')}>
            <p className="text-sm lg:text-base">
              {t('teachers-receive-technical')}
            </p>
          </HowItWorksElement>
        </div>
      </FullBlock>

      <FullBlock header={t('still-have-questions')}>
        <div className="flex flex-col w-full">
          <QuestionLine
            question={t('is-the-material-of-the-laborat')}
            number="01"
          >
            <p>{t('our-laboratories-are-suitable-')}</p>
          </QuestionLine>
          <QuestionLine
            question={t('we-are-a-school-how-can-we-sub')}
            number="02"
          >
            <p>
              {t('please-contact-our-customer-su')}
              <Link to="/contacts" className="text-blue-500">
                {t('book-a-call')}
              </Link>
            </p>
          </QuestionLine>
          <QuestionLine
            question={t('what-does-the-subscription-con')}
            number="03"
          >
            <p>{t('subscription-to-xreadylab-inte')}</p>
          </QuestionLine>
          <QuestionLine
            question={t('what-is-included-in-the-vr-class-system')}
            number="04"
          >
            <ul>
              <li>{t('broadcast-system-to-track-all-students')}</li>
              <li>{t('the-simultaneous-launch-of-laboratories-')}</li>
              <li>{t('ability-to-edit-scripts-and-insert-your-')}</li>
              <li>{t('you-can-launch-the-content-from')}</li>
            </ul>
          </QuestionLine>
          <QuestionLine
            question={t('at-what-age-can-we-use-vr-headsets')}
            number="06"
          >
            <p>{t('we-adhere-to-the-worlds-generally-accept')}</p>
          </QuestionLine>
        </div>
      </FullBlock>

      <FullBlock header={t('catalog-of-our-simulations')} isDark>
        <div className="grid gap-8 grid-cols-1 lg:grid-cols-3 mb-12">
          {rndCourses.isSuccess &&
            rndCourses.data.map((el, index) => (
              <CourseCard data={el} key={index} whiteTheme />
            ))}
        </div>

        <Link to="/simulations" className="w-auto mx-auto">
          <Button className="mx-auto block">{t('view-all-simulations')}</Button>
        </Link>
      </FullBlock>
    </>
  );
};

export default HomePage;
