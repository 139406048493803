import classNames from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {AddCourseDAO} from '../../../../api/courses/dao/CreateCourseRequestDAO';
import {
  CourseSection,
  CourseSubject,
  CourseTranslationDTO,
} from '../../../../api/courses/dto/CourseDTO';
import {useGetAllLanguages} from '../../../../api/localization/Localization';
import TranslationEditor, {
  HollowCourseTranslationDTO,
} from './TranslationEditor';
import LoadingEditorBlock from '../../../editor/elements/common/LoadingBlock';
import {SubscribeToPersistStorage} from '../../../editor/types/UseEditor';
import FormButton from '../../../form/button/FormButton';
import ImageSelectorInput from '../../../form/imageSelector/ImageSelectorInput';
import Input from '../../../form/input/Input';
import ListBox, {ListBoxOption} from '../../../form/listbox/Listbox';
import {
  useAddNewCourse,
  useEditCourse,
} from '../../../../api/courses/CoursesMutations';
import {
  SaveBlocks,
  PrepareTranslationKeys,
  TranslationKey,
  ApplyTranslationKeys,
} from '../../../editor/service/ContentEditor/Helpers';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../services/globalNotification/universalNotificationHandler';
import {Link, useNavigate} from 'react-router-dom';
import {AiOutlineRollback} from 'react-icons/ai';
import {initLocalSave} from '../../../../services/utils/localSaver';
import Toggle from '../../../form/switch/Toggle';
import {LocalizationDTO} from '../../../../api/localization/dto/LocalizationDTO';
import {useTranslate} from '../../../../api/localization/LocalizationMutations';
import {TranslationResponseDTO} from '../../../../api/localization/dto/TranslationDTO';
import {RecursiveRndID} from '../../../editor/service/copyPaste/CopyEditorBlocks';

interface CourseEditorProps {
  courseData?: AddCourseDAO;
  courseId?: string;
}

const CourseEditor: React.FC<CourseEditorProps> = ({courseData, courseId}) => {
  const navigation = useNavigate();
  const createCourse = useAddNewCourse({
    onSuccess: () => {
      UniversalNotificationHandler(
        'Created',
        'Created',
        NotificationType.success,
      );
      navigation('/admin/simulations');
    },
  });
  const updateCourse = useEditCourse(courseId || '', {
    onSuccess: () => {
      UniversalNotificationHandler('Saved', 'saved', NotificationType.success);
    },
  });

  const [courseCategories] = useState<ListBoxOption<CourseSection>[]>(
    (Object.keys(CourseSection) as (keyof typeof CourseSection)[]).map(
      (key) => {
        return {
          label: CourseSection[key],
          value: CourseSection[key],
        };
      },
    ),
  );

  const [courseSubjects] = useState<ListBoxOption<CourseSubject>[]>(
    (Object.keys(CourseSubject) as (keyof typeof CourseSubject)[]).map(
      (key) => {
        return {
          label: CourseSubject[key],
          value: CourseSubject[key],
        };
      },
    ),
  );

  const [translationsData, setTranslationsData] = useState<{
    [key: string]: HollowCourseTranslationDTO;
  }>({});

  //const [currentLanguage, setCurrentLanguage] = useState(0);
  const [selectedLang, setSelectedLang] = useState<LocalizationDTO>();

  const [isBlockSelector, setIsBlockSelector] = useState(false);

  const languages = useGetAllLanguages({
    onSuccess: (elems) =>
      setSelectedLang(elems.find((x) => x.languageCode === 'en') || elems[0]),
  });

  useEffect(() => {
    SubscribeToPersistStorage();
  }, []);

  const [courseTranslations, setCourseTranslations] = useState<
    CourseTranslationDTO[]
  >(courseData?.translations ?? []);

  const applyTranslations = useCallback(
    (translations: TranslationResponseDTO[]) => {
      //debugger;

      const language = languages.data?.find((x) => x.languageCode === 'en');
      if (!language) return;
      const element = translationsData[language.id];

      translations.forEach((rawTranslation) => {
        //const language = languages.data
        const result = ApplyTranslationKeys(
          element.bodyId,
          rawTranslation.elements,
        );

        if (!result) return;

        RecursiveRndID(result);

        const targetLanguage = languages.data?.find(
          (x) => x.languageCode === rawTranslation.languageCode,
        );

        if (!targetLanguage) return;
        const translatedNewElement: CourseTranslationDTO = {
          languageId: targetLanguage.id,
          name:
            rawTranslation.elements.find((x) => x.key === 'name')?.value ?? '',
          description:
            rawTranslation.elements.find((x) => x.key === 'description')
              ?.value ?? '',
          bodyId: result.id,
          body: JSON.stringify(result),
        };

        setCourseTranslations((old) => {
          const oldArr = old.filter((x) => x.languageId !== targetLanguage.id);
          return [...oldArr, translatedNewElement];
        });

        setTranslationsData((old) => ({
          ...old,
          [targetLanguage.id]: {
            languageId: targetLanguage.id,
            name:
              rawTranslation.elements.find((x) => x.key === 'name')?.value ??
              '',
            description:
              rawTranslation.elements.find((x) => x.key === 'description')
                ?.value ?? '',
            bodyId: result.id,
          },
        }));
      });
      setIsBlockSelector(false);

      UniversalNotificationHandler(
        'Done',
        'All cources have been translated',
        NotificationType.success,
      );
    },
    [languages.data, translationsData],
  );

  const getTranslation = useTranslate({
    onSuccess: applyTranslations,
  });

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: {errors, isSubmitted, isValid, submitCount},
  } = useForm<Omit<AddCourseDAO, 'translations'>>({
    defaultValues: {
      section: CourseSection.VR,
      subject: CourseSubject.Biology,
      duration: 15,
      priority: 10,
      ...courseData,
    },
  });

  const languageSelector = useForm<{language: LocalizationDTO}>();

  useEffect(() => {
    if (isSubmitted && !isValid) {
      Object.entries(errors).forEach(([, value]) => {
        UniversalNotificationHandler(
          'Incomplete!',
          value.message,
          NotificationType.danger,
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount]);

  const getSaveData = useCallback(
    (mainFormData: Omit<AddCourseDAO, 'translations'>): AddCourseDAO => {
      const savedBlocks: CourseTranslationDTO[] = [];

      // debugger;
      Object.keys(translationsData).forEach((key) => {
        //debugger;
        let body = JSON.stringify(SaveBlocks(translationsData[key].bodyId));
        if (!body) {
          body =
            courseTranslations.find(
              (x) => x.languageId === translationsData[key].languageId,
            )?.body ?? '';
        }
        savedBlocks.push({...translationsData[key], body});
      });

      const data: AddCourseDAO = {
        ...mainFormData,
        translations: savedBlocks,
      };

      return data;
    },
    [translationsData],
  );

  const translate = useCallback(() => {
    if (!selectedLang) return;

    const element = translationsData[selectedLang.id];
    const data = PrepareTranslationKeys(element.bodyId);
    const additionalKeys: TranslationKey[] = [
      {
        key: 'name',
        value: element.name,
      },
      {
        key: 'description',
        value: element.description,
      },
    ];
    const targetLanguages =
      languages.data
        ?.filter((x) => x.id !== selectedLang.id && x.languageCode !== 'ru')
        .map((x) => x.languageCode) ?? [];

    setIsBlockSelector(true);
    UniversalNotificationHandler(
      'Translation started',
      'Translation started',
      NotificationType.warning,
    );

    getTranslation.mutate({
      sourceLanguage: selectedLang.languageCode,
      targetLanguage: targetLanguages,
      elements: [...data, ...additionalKeys],
    });
  }, [getTranslation, languages.data, selectedLang, translationsData]);

  const OnSave = useCallback(
    (mainFormData: Omit<AddCourseDAO, 'translations'>) => {
      const data = getSaveData(mainFormData);

      if (!courseId) createCourse.mutate(data);
      else updateCourse.mutate(data);
    },
    [courseId, createCourse, getSaveData, updateCourse],
  );

  if (!languages.isSuccess) return <LoadingEditorBlock />;
  return (
    <form
      className="my-4"
      onSubmit={handleSubmit(OnSave)}
      onInvalid={() => alert('qwe')}
      onInvalidCapture={() => alert('qwe')}
    >
      <div className="gap-2 flex mb-3">
        <Link to="/admin/simulations" className="bg-white w-fit">
          <FormButton>
            <div className="flex justify-between items-center gap-2">
              <AiOutlineRollback /> Back
            </div>
          </FormButton>
        </Link>
        {courseId && (
          <Link to={`/admin/simulations/${courseId}`} target="_blank">
            <FormButton>Open</FormButton>
          </Link>
        )}
      </div>
      <div className="gap-2 grid">
        <div className="flex gap-2">
          <ListBox
            name="section"
            className="flex-1"
            options={courseCategories}
            label="Category"
            setValue={setValue}
            getValues={getValues}
          />
          <ListBox
            name="subject"
            className="flex-1"
            options={courseSubjects}
            label="Subject"
            setValue={setValue}
            getValues={getValues}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-4 md:gap-2">
          <ImageSelectorInput
            label="Thumbnail image"
            name="thumbnailImageId"
            registration={{register, errors}}
            getValues={getValues}
            onChange={(name, value) => {
              setValue(name, value[0].id);
            }}
            options={{required: 'Thumbnail image must be set'}}
            maxFiles={1}
          />
          <ImageSelectorInput
            label="Background image"
            name="backgroundImageId"
            registration={{register, errors}}
            getValues={getValues}
            onChange={(name, value) => {
              setValue(name, value[0].id);
            }}
            options={{required: 'Background image must be set'}}
            maxFiles={1}
          />

          <Input
            label="Duration"
            name="duration"
            type="number"
            registration={{register, errors}}
            options={{required: 'Required'}}
          />
          <Input
            label="URL short name"
            name="urlName"
            type="text"
            className="grow"
            registration={{register, errors}}
            options={{
              required: 'Required',
              pattern: {
                value: /^[a-zA-Z0-9\-\_]+$/,
                message: 'Must be only numbers, letters, - and _',
              },
            }}
          />
          <Input
            label="Priority"
            name="priority"
            type="number"
            registration={{register, errors}}
            options={{required: 'Required'}}
          />
          <Toggle
            label="Preview mode"
            name="isPreview"
            setValue={setValue}
            getValues={getValues}
          />
        </div>
      </div>
      <hr className="my-4" />
      <div className="grid gap-2">
        <div className="flex gap-2 mb-2">
          <ListBox
            className="grow"
            name="language"
            getValues={languageSelector.getValues}
            setValue={languageSelector.setValue}
            options={languages.data.map((el) => ({
              label: el.name,
              value: el,
            }))}
            onChange={setSelectedLang}
            disabled={isBlockSelector}
          />
          <FormButton onClick={() => translate()}>
            Translate to other languages
          </FormButton>
        </div>
        {selectedLang && (
          // languages.data.fin((el) => (
          <TranslationEditor
            key={selectedLang.id}
            language={selectedLang}
            className={classNames(
              selectedLang.id !== selectedLang.id && 'hidden',
            )}
            onFormUpdated={(data) =>
              setTranslationsData((old) => ({
                ...old,
                [data.languageId]: data,
              }))
            }
            originalTranslation={courseTranslations.find(
              (x) => x.languageId === selectedLang.id,
            )}
            // originalTranslation={courseData?.translations.find(
            //   (x) => x.languageId == el.id,
            // )}
          />
        )}
        {/* {selectedLang &&
          languages.data.map((el) => (
            <TranslationEditor
              key={el.id}
              language={el}
              className={classNames(selectedLang.id !== el.id && 'hidden')}
              onFormUpdated={(data) =>
                setTranslationsData((old) => ({
                  ...old,
                  [data.languageId]: data,
                }))
              }
              originalTranslation={courseTranslations.find(
                (x) => x.languageId === el.id,
              )}
              // originalTranslation={courseData?.translations.find(
              //   (x) => x.languageId == el.id,
              // )}
            />
          ))} */}
        <div className="flex gap-2">
          <FormButton type="submit" className="bg-pink-500 text-white flex-1">
            Save
          </FormButton>
          <FormButton
            onClick={() => {
              const data = getSaveData(getValues());
              initLocalSave(data, `simulation-${courseId || 'new'}`);
            }}
            className="flex-shrink"
          >
            Save local
          </FormButton>
        </div>
      </div>
    </form>
  );
};

export default CourseEditor;
