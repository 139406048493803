import React from 'react';
import {Link} from 'react-router-dom';
import Button from '../../components/form/button/Button';
import CoursesBlock from '../../components/schoolCourses/CoursesBlock';
import CoursesParagraph from '../policies/dolcevita/CoursesParagraph';

const SchoolCoursesPageCom: React.FC = () => {
  return (
    <section className="mb-5 mt-2 mx-auto p-3 max-w-screen-xl">
      <h2 className="text-center mx-auto mb-4 text-4xl font-medium">
        Create VR games with us
      </h2>
      <div className="w-full mb-3 mx-auto">
        <img
          src="https://d33wubrfki0l68.cloudfront.net/0057d5d51a652c15f834882211a6494afad51236/0d109/courselogo.jpg"
          className="w-full"
          alt="VR games"
        />
        <small className="block">
          https://covers.alphacoders.com/index.php/cover/view/57663
        </small>
      </div>
      <CoursesParagraph>
        <div className="space-y-2">
          <p>
            <b>Welcome! </b>It's no secret that virtual reality has taken the
            world by storm. From immersive games like Half-Life Alyx to
            exhilarating VR roller coasters, and even virtual concerts, the
            possibilities are endless. But have you ever considered taking it
            one step further and creating your own worlds? Imagine designing
            unique characters, crafting intricate storylines, and building
            entire universes from scratch.
          </p>
          <p>
            The truth is, the demand for skilled VR game developers has never
            been higher. And while programming is certainly a crucial aspect,
            it's just the tip of the iceberg. The artistry and creativity
            involved in game development are what truly sets the best developers
            apart.
          </p>
          <p>
            So why not explore the exciting world of VR game development? With
            the right training and dedication, you too can become a part of this
            rapidly growing industry. Start crafting your own virtual reality
            experiences today!
          </p>
        </div>
      </CoursesParagraph>
      <CoursesParagraph>
        <b>
          If you want to organize VR development in your school, here you can{' '}
          <Link
            to="/contacts"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:underline hover:text-blue-800"
          >
            contact with our team
          </Link>{' '}
          and launch courses with us!
        </b>
      </CoursesParagraph>
      <CoursesParagraph>
        <b> Duration: 3 months, 3 academic hours per week</b>
        <br />
        <b> Age: 12+</b>
        <br />
      </CoursesParagraph>
      <CoursesBlock
        header="What will you learn?"
        list={[
          'Game design basics',
          'How to work with the Unity game development engine',
          'Methods for designing a game and selecting game mechanics',
          'How VR applications are designed taking into account the physiological characteristics of a person',
          'Basics of the C# language in the context of development for the Unity engine',
          'The principles of game physics, light and sound settings in games',
        ]}
        image="https://d33wubrfki0l68.cloudfront.net/0b328ec156e02bd34bb122994489f75eda39de1a/15e19/course_1.jpg"
      />
      <CoursesBlock
        header="What will you learn?"
        list={[
          'Develop simple games for virtual reality helmets',
          'Write C# code in Visual Studio',
          'Work with the Unity Editor',
          'Work with major VR SDKs - Oculus SDK and OpenVR SDK',
          'Create game animations and work with physics',
        ]}
        image="https://d33wubrfki0l68.cloudfront.net/bdf20658c2eec17846360b6bd8ea6e4dfe61c6a6/2849a/course_2.jpg"
      />
      <div className="flex justify-between my-16 flex-col md:flex-row gap-4">
        <a
          href="https://buy.stripe.com/3cs02ieuTd9X81O289"
          className="block w-full px-0 md:px-4"
          rel="noreferrer"
        >
          <Button className="w-full" isShadow>
            Pay 1 month
          </Button>
        </a>

        <a
          href="https://buy.stripe.com/7sIbL0euT2vj95S9AC"
          className="block w-full px-0 md:px-4"
          rel="noreferrer"
        >
          <Button className="w-full" isShadow>
            Pay for the full course
          </Button>
        </a>
      </div>
    </section>
  );
};

export default SchoolCoursesPageCom;
