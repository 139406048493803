import {useMutation, useQuery, UseQueryResult} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  UseShortMutationOptions,
  UseShortQueryOptions,
} from '../../helpers/ReactQuesyOptionBuilder';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import APIRequest from '../Api';
import {ApiError} from '../basicTypes/ApiError';
import {LocalizationDTO} from './dto/LocalizationDTO';
import {TranslationDTO, TranslationResponseDTO} from './dto/TranslationDTO';
import {queryClient} from '../../app/App';
import {GetAllCustomPagesKey} from '../customPage/CustomPage';
import {CreateCustomPageDAO} from '../customPage/dao/CustomPageDAO';

const GetAllLanguagesKey = 'localization/languages';
const TranslateKey = 'localization/translate';

export function useGetAllLanguages(
  options?: UseShortQueryOptions<LocalizationDTO[], AxiosError<ApiError>>,
): UseQueryResult<LocalizationDTO[], AxiosError<ApiError>> {
  return useQuery(
    [GetAllLanguagesKey],
    async (): Promise<LocalizationDTO[]> => {
      const {data} = await APIRequest.get<LocalizationDTO[]>(
        `/Localisation/GetAllLanguages`,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useTranslateKeys(
  request: TranslationDTO,
  options?: UseShortQueryOptions<TranslationResponseDTO, AxiosError<ApiError>>,
): UseQueryResult<TranslationResponseDTO, AxiosError<ApiError>> {
  return useQuery(
    [GetAllLanguagesKey],
    async (): Promise<TranslationResponseDTO> => {
      const {data} = await APIRequest.get<TranslationResponseDTO>(
        `/Localisation/Translate`,
      );
      return data;
    },
    {
      onError: options?.onError || GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export const useTranslate = (
  options?: UseShortMutationOptions<
    TranslationResponseDTO[],
    AxiosError<ApiError>,
    TranslationDTO
  >,
) => {
  return useMutation<
    TranslationResponseDTO[],
    AxiosError<ApiError>,
    TranslationDTO
  >(
    [TranslateKey],
    async (
      keysToTranslate: TranslationDTO,
    ): Promise<TranslationResponseDTO[]> => {
      const {data} = await APIRequest.post<TranslationResponseDTO[]>(
        `/Localisation/Translate`,
        keysToTranslate,
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GetAllCustomPagesKey]);
      },
      onError: options?.onError || GlobalNotificationErrorHandler,
      ...options,
    },
  );
};
