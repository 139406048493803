import React from 'react';
import {useTranslation} from 'react-i18next';
import {BiTime} from 'react-icons/bi';
import ServerImage from '../../serverImage/ServerImage';
import './CoursePageHeader.css';

interface SimulationPageHeaderParams {
  subject: string;
  device: string;
  duration: number;
  title: string;
  background: string;
}

const CoursePageHeader: React.FC<SimulationPageHeaderParams> = ({
  subject,
  device,
  duration,
  title,
  background,
}) => {
  const {t} = useTranslation('simulations-page');
  return (
    <section className="simulation-page-head relative h-64 bg-gray-400">
      <div className="pl-5 lg:pl-2 lg:ml-0 w-full simulation-title absolute left-1/2 bottom-5 lg:bottom-16 text-white z-20">
        <div className="flex items-center">
          <span className="font-medium text-base lg:text-3xl">{subject}</span>
          <span className="mx-6">|</span>
          <span className="text-base lg:text-3xl">{device}</span>
          <span className="flex text-base lg:text-3xl items-center lg:hidden">
            <span className="mx-6">|</span>
            <BiTime className="mr-1" />
            {duration} {t('minutes')}
          </span>
        </div>
        <h1 className="mt-0 lg:mt-2 font-bold text-xl lg:text-6xl uppercase text-white">
          {title}
        </h1>
      </div>
      <div className="simulation-gradient w-full h-full left-0 top-0 absolute z-10" />
      <ServerImage
        imageId={background}
        alt={title}
        className="min-w-full max-w-full simulation-image transform"
      />
    </section>
  );
};

export default CoursePageHeader;
