import classNames from 'classnames';
import {useCallback, useEffect, useState} from 'react';

export interface ToggleInputProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  isDefaultActive: boolean;
  onUpdated: (state: boolean) => void;
}

const ToggleInput: React.FC<ToggleInputProps> = ({
  title,
  isDefaultActive,
  onUpdated,
  className,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(isDefaultActive);
  const onToggle = useCallback(() => {
    setIsActive((old) => {
      onUpdated(!old);
      return !old;
    });
  }, [onUpdated]);

  useEffect(() => {
    setIsActive(isDefaultActive);
  }, [isDefaultActive]);

  return (
    <div
      className={classNames(
        className,
        'flex items-center h-[2.5rem] hover:opacity-70 cursor-pointer',
      )}
      onClick={() => onToggle()}
      {...rest}
    >
      <div
        className={classNames(
          'w-[4rem] h-[2rem]  rounded-full flex items-center relative ',
          isActive ? 'bg-green-400' : 'bg-gray-300',
        )}
      >
        <div
          className={classNames(
            'absolute w-[1.8rem]  aspect-square rounded-full transition-all duration-75',
            isActive
              ? 'left-[2.1rem] bg-white border'
              : 'left-[0.1rem] bg-gray-600',
          )}
        ></div>
      </div>
      <span className="grow left-2 transition-all bg-white px-1 text-sm ml-1">
        {title}
      </span>
    </div>
  );
};

export default ToggleInput;
