import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {AiOutlineRollback} from 'react-icons/ai';
import {Link, useNavigate} from 'react-router-dom';
import {useGetAdminCourses} from '../../../../api/courses/Courses';
import {AddCourseDAO} from '../../../../api/courses/dao/CreateCourseRequestDAO';
import {
  CourseSection,
  CourseSubject,
} from '../../../../api/courses/dto/CourseDTO';
import {useGetAllLanguages} from '../../../../api/localization/Localization';
import AdminCourseCard from '../../../../components/admin/adminCourses/adminCourseCard/AdminCourseCard';
import LoadingEditorBlock from '../../../../components/editor/elements/common/LoadingBlock';
import FormButton from '../../../../components/form/button/FormButton';
import Input from '../../../../components/form/input/Input';
import ListBox, {
  ListBoxOption,
} from '../../../../components/form/listbox/Listbox';

interface SimulationsFilter {
  search: string;
  section?: CourseSection;
  subject?: CourseSubject;
}

const AdminCoursesPages: React.FC = () => {
  const {t} = useTranslation('simulations-page');
  const allSimulations = useGetAdminCourses();

  const [courseCategories] = useState<
    ListBoxOption<CourseSection | undefined>[]
  >([
    {label: 'Any', value: undefined},
    ...(Object.keys(CourseSection) as (keyof typeof CourseSection)[]).map(
      (key) => {
        return {
          label: CourseSection[key],
          value: CourseSection[key],
        };
      },
    ),
  ]);

  const [courseSubjects] = useState<ListBoxOption<CourseSubject | undefined>[]>(
    [
      {label: 'Any', value: undefined},
      ...(Object.keys(CourseSubject) as (keyof typeof CourseSubject)[]).map(
        (key) => {
          return {
            label: CourseSubject[key],
            value: CourseSubject[key],
          };
        },
      ),
    ],
  );

  const {
    register,
    getValues,
    setValue,
    watch,
    formState: {errors},
  } = useForm<SimulationsFilter>();

  const [searchText, setSearchText] = useState('');
  const [currentSection, setCurrentSection] = useState<CourseSection>();
  const [currentSubject, setCurrentSubject] = useState<CourseSubject>();

  useEffect(() => {
    const subscription = watch((data) => {
      setSearchText(data.search || '');
      setCurrentSection(data.section);
      setCurrentSubject(data.subject);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [currentLanguage, setCurrentLanguage] = useState<string>();

  const languages = useGetAllLanguages({
    onSuccess: (data) => {
      setCurrentLanguage(data[0].id);
    },
  });

  const history = useNavigate();

  const searchTextFilter = useCallback(
    (element: AddCourseDAO): boolean => {
      const request = searchText.toLowerCase();

      for (const item of element.translations) {
        if (item.name.toLowerCase().includes(request)) return true;

        if (item.description.toLocaleLowerCase().includes(request)) return true;
      }

      return false;
    },
    [searchText],
  );

  const searchSectionFilter = useCallback(
    (element: AddCourseDAO): boolean => {
      if (!currentSection && !currentSubject) return true;

      if (currentSection && currentSubject)
        return (
          currentSection === element.section &&
          currentSubject === element.subject
        );

      if (currentSection && currentSection === element.section) return true;
      if (currentSubject && currentSubject === element.subject) return true;

      return false;
    },
    [currentSection, currentSubject],
  );

  if (!allSimulations.isSuccess || !languages.isSuccess)
    return <LoadingEditorBlock />;
  return (
    <>
      <section>
        <h1 className="my-10 font-bold text-6xl text-center uppercase text-indigo-900">
          {t('katalog-simulyacii')}
        </h1>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 mb-2">
            <Link to="/admin" className="bg-white w-fit">
              <FormButton>
                <div className="flex justify-between items-center gap-2">
                  <AiOutlineRollback /> Back
                </div>
              </FormButton>
            </Link>
            {/* <div className="flex gap-2">
              {languages.data.map((el) => (
                <div className="bg-white" key={el.id}>
                  <FormButton
                    onClick={() => setCurrentLanguage(el.id)}
                    selected={el.id == currentLanguage}
                  >
                    {el.name}
                  </FormButton>
                </div>
              ))}
            </div> */}
            <FormButton
              className="flex-1 hover:text-black hover:font-semibold"
              highlight
              onClick={() => history('/admin/simulations/add-simulation')}
            >
              Add new simulation
            </FormButton>
          </div>
          <div className="flex gap-2">
            <Input
              name="search"
              label="Search"
              className="flex-grow"
              registration={{register, errors}}
            />
            <ListBox
              name="section"
              className="flex-1"
              options={courseCategories}
              label="Category"
              setValue={setValue}
              getValues={getValues}
            />
            <ListBox
              name="subject"
              className="flex-1"
              options={courseSubjects}
              label="Subject"
              setValue={setValue}
              getValues={getValues}
            />
          </div>
        </div>
      </section>
      <section className="grid grid-cols-1 lg:grid-cols-2 max-w-screen-sm lg:max-w-screen-xl mx-auto gap-8 my-6">
        {allSimulations.isSuccess &&
          allSimulations.data
            .filter(searchTextFilter)
            .filter(searchSectionFilter)
            .map((element) => (
              <AdminCourseCard
                data={{
                  ...element,
                  translation:
                    element.translations.find(
                      (x) => x.languageId == currentLanguage,
                    ) || element.translations[0],
                }}
                key={element.id}
              />
            ))}
      </section>
    </>
  );
};

export default AdminCoursesPages;
