import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {BiTime} from 'react-icons/bi';
import {Link, useNavigate} from 'react-router-dom';
import {useDeleteCourse} from '../../../../api/courses/CoursesMutations';
import {CourseDTO} from '../../../../api/courses/dto/CourseDTO';
import {useConfirmationAlert} from '../../../../services/confirmationAlert/ConfirmationAlertService';
import FormButton from '../../../form/button/FormButton';
import ServerImage from '../../../serverImage/ServerImage';

interface AdminCourseCardProps {
  data: CourseDTO;
}

const AdminCourseCard: React.FC<AdminCourseCardProps> = ({data}) => {
  const navigation = useNavigate();

  const commonTranslation = useTranslation('common');
  const {t} = useTranslation('simulations-page');
  const deleteCourse = useDeleteCourse();

  const confirmationAlert = useConfirmationAlert();

  const onDeleteSimulation = useCallback(
    (id: string) => {
      confirmationAlert.showAlert({
        message: `Are you sure you want to delete this course ${id}?`,
        onCancel: () => {},
        onConfirm: () => {
          deleteCourse.mutate(id);
        },
      });
    },
    [confirmationAlert, deleteCourse],
  );

  return (
    <div className="relative">
      <div className="flex">
        <FormButton
          onClick={() => onDeleteSimulation(data.id)}
          className="bg-red-500 text-white hover:bg-red-400"
        >
          Delete
        </FormButton>
        <FormButton
          onClick={() => navigation(`/admin/simulations/${data.id}`)}
          className="grow"
        >
          Tech preview
        </FormButton>
        <FormButton onClick={() => navigation(`/simulations/${data.id}`)}>
          Open
        </FormButton>
      </div>
      <Link
        to={`/admin/simulations/edit/${data.id}`}
        className="w-full relative"
      >
        <div className="h-80 w-full relative">
          <ServerImage
            imageId={data.thumbnailImageId}
            className="w-full h-full object-cover"
            alt="simulation"
          />
          <div className="w-full h-full absolute filter-simulation top-0" />
          <div className="absolute px-4 w-full bottom-0 ">
            <div className="border-t border-solid border-white text-white py-2">
              <span className="mr-4 font-semibold text-lg">
                {commonTranslation.t(`CourseSubject.${data.subject}`)}
              </span>
              |
              <span className="ml-4 font-normal text-lg">
                {commonTranslation.t(`CourseSection.${data.section}`)}
              </span>
              <div className="flex items-center float-right">
                <BiTime />
                <span className="text-right ml-1.5">
                  {data.duration} {t('minutes')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <h2 className="mb-2 lg:mb-4 uppercase font-bold text-xl lg:text-3xl text-indigo-900">
            {data.translation.name}
          </h2>
          <div className="text-sm lg:text-lg mb-2 overflow-hidden line-clamp-3 text-black">
            {data.translation.description}
          </div>
          <span className="font-bold text-lg text-blue-600">
            {t('more')}...
          </span>
        </div>
      </Link>
    </div>
  );
};

export default AdminCourseCard;
