import {Routes, Route, BrowserRouter} from 'react-router-dom';
import ReactGA from 'react-ga4';
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import CourseAdminPreview from '../../pages/admin/course/adminCourse/CourseAdminPreview';
import AdminMainPage from '../../pages/admin/adminMainPage/AdminMainPage';
import CoursePage from '../../pages/coursePage/CoursePage';
import HomePage from '../../pages/homePage/HomePage';
import HomePageRU from '../../pages/homePage/HomePageRU';
import LoginPage from '../../pages/login/LoginPage';
import PolicyPage from '../../pages/policies/policyPage/PolicyPage';
import WebsiteTermsPage from '../../pages/policies/websiteTermsPage/WebsiteTermsPage';
import MainPageLayout from '../layouts/mainPageLayout/MainPageLayout';
import CoursesEditLivePreview from '../../pages/admin/course/adminCourse/CoursesEditLivePreview';
import ContentLayout from '../layouts/contentLayout/ContentLayout';
import CoursesPage from '../../pages/coursesPage/CoursesPage';
import FlagPage from '../../components/flagPage/flagPage';
import LocaleRoute from '../routerMiddlewares/LocaleRoute';
import PrivateRoute from '../routerMiddlewares/PrivateRoute';
import CustomPage from '../../pages/customPage/CustomPage';
import Dashboard from '../../pages/library/Dashboard';
import Contacts from '../../pages/contacts/Contacts';
import ContactsSuccess from '../../pages/contacts/Success';
import LiveEditorViewer from '../../pages/home/LiveEditorViewer';
import AdminCoursesPages from '../../pages/admin/course/adminCourses/AdminCoursesPage';
import CreateCourse from '../../pages/admin/course/createCourse/CreateCourse';
import EditCourse from '../../pages/admin/course/createCourse/EditCourse';
import CreateCustomPage from '../../pages/admin/customPage/adminCreateCustomPage/CreateCustomPage';
import EditCustomPage from '../../pages/admin/customPage/adminCreateCustomPage/EditCustomPage';
import CustomPageAdminPreview from '../../pages/admin/customPage/adminCustomPage/CustomPageAdminPreview';
import AdminCustomPages from '../../pages/admin/customPage/adminCustomPages/AdminCustomPages';
import PrivacyPolicyPage from '../../pages/policies/privacyPolicyPage/PrivacyPolicyPage';
import DnaAppPolicy from '../../pages/policies/dna/dnaPolicy';
import DolcevitaPage from '../../pages/policies/dolcevita/DolcevitaPolicy';
import PlantCellAppPolicy from '../../pages/policies/plantCell/PlantCellPolicy';
import SchoolCoursePageWrapper from '../../pages/schoolCourses/CoursePageWrapper';
import PaymentinfoPolicy from '../../pages/policies/paymentInfo/PaymentInfo';
import SampleDashboard from '../../pages/library/SampleDashboard';
import HomePageEducators from '../../pages/homePage/HomePageEducators';
import EducatorsPageLayout from '../layouts/special/EducatorsPageLayout';
import RequestDemo from '../../pages/contacts/RequestDemo';
import LocationChangeHandler from '../../components/common/utils/LocationChanged';
import UniversalPolicyPico from '../../pages/policies/UniversalPolicyPico';

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <LocationChangeHandler
      onPathChanged={(path) => ReactGA.send({hitType: 'pageview', page: path})}
    />
    <Routes>
      <Route path="/">
        <Route element={<MainPageLayout />}>
          <Route
            index
            element={<LocaleRoute ru={<HomePageRU />} en={<HomePage />} />}
          />
        </Route>
      </Route>

      <Route path="/educators">
        <Route element={<EducatorsPageLayout />}>
          <Route index element={<HomePageEducators />} />
        </Route>
      </Route>

      <Route path="/">
        <Route element={<BasicLayout />}>
          <Route path="/region" element={<FlagPage />} />
          <Route path="/simulations" element={<CoursesPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/website-terms" element={<WebsiteTermsPage />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/request-demo" element={<RequestDemo />} />
          <Route path="/contacts/success" element={<ContactsSuccess />} />
          <Route path="/courses" element={<SchoolCoursePageWrapper />} />
        </Route>
        <Route element={<ContentLayout />}>
          <Route path="/simulations/:courseId" element={<CoursePage />} />
        </Route>
      </Route>

      <Route path="/p">
        <Route element={<BasicLayout />}>
          <Route path="/p/:page" element={<CustomPage />} />
          <Route path="/p/:subsection/:page" element={<CustomPage />} />
          <Route
            path="/p/:section/:subsection/:page"
            element={<CustomPage />}
          />
        </Route>
      </Route>

      <Route path="/policies" element={<BasicLayout />}>
        <Route
          path="/policies/privacy-policy"
          element={<PrivacyPolicyPage />}
        />
        <Route path="/policies/policy" element={<PolicyPage />} />
        <Route path="/policies/paymentinfo" element={<PaymentinfoPolicy />} />

        <Route path="/policies/ios/dolcevita" element={<DolcevitaPage />} />
        <Route path="/policies/oculus/xreadydna" element={<DnaAppPolicy />} />
        <Route
          path="/policies/oculus/xreadyplantcell"
          element={<PlantCellAppPolicy />}
        />
        <Route
          path="/policies/pico/xreadyplantcell"
          element={
            <UniversalPolicyPico labName={'Biology VR PLant Cell Structure'} />
          }
        />
        <Route
          path="/policies/pico/mitosis"
          element={<UniversalPolicyPico labName={'Biology VR Mitosis'} />}
        />
        <Route
          path="/policies/pico/gas-exchange"
          element={<UniversalPolicyPico labName={'Biology VR Gas Exchange'} />}
        />
        <Route
          path="/policies/pico/photosynthesis"
          element={
            <UniversalPolicyPico labName={'Biology VR Photosynthesis'} />
          }
        />
        <Route
          path="/policies/pico/electrification"
          element={
            <UniversalPolicyPico labName={'Physics VR Electrification'} />
          }
        />
      </Route>

      <Route path="/library" element={<BasicLayout />}>
        <Route element={<PrivateRoute />}>
          <Route path="/library/dashboard" element={<Dashboard />} />
        </Route>
        <Route path="/library/sample" element={<SampleDashboard />} />
      </Route>

      <Route path="/admin" element={<PrivateRoute />}>
        <Route path="/admin" element={<BasicLayout />}>
          <Route index element={<AdminMainPage />} />

          {/* Simulations: */}
          <Route path="/admin/simulations/" element={<AdminCoursesPages />} />
          <Route
            path="/admin/simulations/add-simulation"
            element={<CreateCourse />}
          />
          <Route
            path="/admin/simulations/edit/:courseId"
            element={<EditCourse />}
          />

          {/* Custom pages: */}
          <Route path="/admin/custom-pages/" element={<AdminCustomPages />} />
          <Route
            path="/admin/custom-pages/add"
            element={<CreateCustomPage />}
          />
          <Route
            path="/admin/custom-pages/:customPageId"
            element={<CustomPageAdminPreview />}
          />
          <Route
            path="/admin/custom-pages/edit/:customPageId"
            element={<EditCustomPage />}
          />
          <Route path="/admin/live/:blockId" element={<LiveEditorViewer />} />
        </Route>

        <Route path="/admin" element={<ContentLayout />}>
          <Route
            path="/admin/simulations/live/:courseId"
            element={<CoursesEditLivePreview />}
          />
          <Route
            path="/admin/simulations/:courseId"
            element={<CourseAdminPreview />}
          />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
