import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useGetCourses} from '../../api/courses/Courses';
import {
  CourseDTO,
  CourseSection,
  CourseSubject,
} from '../../api/courses/dto/CourseDTO';
import CourseCard from '../../components/courses/CourseCard';
import ListBox, {ListBoxOption} from '../../components/form/listbox/Listbox';

interface CoursesFilter {
  section?: CourseSection;
  subject?: CourseSubject;
}

const CoursesPage: React.FC = () => {
  const {t, i18n} = useTranslation(['simulations-page', 'common']);
  const simulations = useGetCourses(i18n.language);

  const [courseCategories] = useState<
    ListBoxOption<CourseSection | undefined>[]
  >([
    {label: t(`common:CourseSection.Any`), value: undefined},
    ...(Object.keys(CourseSection) as (keyof typeof CourseSection)[]).map(
      (key) => {
        return {
          label: t(`common:CourseSection.${CourseSection[key]}`),
          value: CourseSection[key],
        };
      },
    ),
  ]);

  const [courseSubjects] = useState<ListBoxOption<CourseSubject | undefined>[]>(
    [
      {label: t(`common:CourseSubject.Any`), value: undefined},
      ...(Object.keys(CourseSubject) as (keyof typeof CourseSubject)[]).map(
        (key) => {
          return {
            label: t(`common:CourseSubject.${CourseSubject[key]}`),
            value: CourseSubject[key],
          };
        },
      ),
    ],
  );

  const {getValues, setValue, watch} = useForm<CoursesFilter>();

  const [currentSection, setCurrentSection] = useState<CourseSection>();
  const [currentSubject, setCurrentSubject] = useState<CourseSubject>();

  useEffect(() => {
    const subscription = watch((data) => {
      setCurrentSection(data.section);
      setCurrentSubject(data.subject);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const searchSectionFilter = useCallback(
    (element: CourseDTO): boolean => {
      if (!currentSection && !currentSubject) return true;

      if (currentSection && currentSubject)
        return (
          currentSection === element.section &&
          currentSubject === element.subject
        );

      if (currentSection && currentSection === element.section) return true;
      if (currentSubject && currentSubject === element.subject) return true;

      return false;
    },
    [currentSection, currentSubject],
  );

  return (
    <>
      <section>
        <h1 className="mb-5 md:my-10 font-bold text-4xl md:text-6xl text-center uppercase text-indigo-900">
          {t('katalog-simulyacii')}
        </h1>
      </section>
      <section className="flex gap-2 lg:w-2/3 mx-auto">
        <ListBox
          name="section"
          className="flex-1"
          options={courseCategories}
          label={t('category')}
          setValue={setValue}
          getValues={getValues}
        />
        <ListBox
          name="subject"
          className="flex-1"
          options={courseSubjects}
          label={t('subject')}
          setValue={setValue}
          getValues={getValues}
        />
      </section>
      <section className="grid grid-cols-1 lg:grid-cols-2 lg:px-6 lg:max-w-screen-xl mx-auto gap-8 my-6">
        {simulations.isSuccess &&
          simulations.data
            .filter(searchSectionFilter)
            .map((element) => <CourseCard data={element} key={element.id} />)}
      </section>
    </>
  );
};

export default CoursesPage;
