import {FlagComponent, US} from 'country-flag-icons/react/3x2';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BasicElement} from '../../helpers/BasicElement';
import {allFlags} from './AllFlags';

type FlagContext = {
  currentFlag: Flag;
  setFlag: (region: string, shortName: string) => void;
  getCurrentLocale: () => string;
};

const flagLanguageTranslator: {flag: string; language: string}[] = [
  {language: 'de', flag: 'at'},
  {language: 'nl', flag: 'be'},
  {language: 'bg', flag: 'bg'},
  {language: 'hr', flag: 'hr'},
  {language: 'el', flag: 'cy'},
  {language: 'cs', flag: 'cz'},
  {language: 'da', flag: 'dk'},
  {language: 'et', flag: 'ee'},
  {language: 'fi', flag: 'fi'},
  {language: 'fr', flag: 'fr'},
  {language: 'de', flag: 'de'},
  {language: 'el', flag: 'gr'},
  {language: 'hu', flag: 'hu'},
  {language: 'ga', flag: 'ie'},
  {language: 'it', flag: 'it'},
  {language: 'lv', flag: 'lv'},
  {language: 'lt', flag: 'lt'},
  {language: 'lb', flag: 'lu'},
  {language: 'mt', flag: 'mt'},
  {language: 'nl', flag: 'nl'},
  {language: 'no', flag: 'no'},
  {language: 'pl', flag: 'pl'},
  {language: 'pt', flag: 'pt'},
  {language: 'ro', flag: 'ro'},
  {language: 'sk', flag: 'sk'},
  {language: 'sl', flag: 'si'},
  {language: 'es', flag: 'es'},
  {language: 'sv', flag: 'se'},
  {language: 'de', flag: 'ch'},
  {language: 'en', flag: 'gb'},
  {language: 'ar', flag: 'ae'},
  {language: 'ar', flag: 'bh'},
  {language: 'bn', flag: 'bd'},
  {language: 'zh-cn', flag: 'cn'},
  {language: 'zh-tw', flag: 'hk'},
  {language: 'hi', flag: 'in'},
  {language: 'id', flag: 'id'},
  {language: 'he', flag: 'il'},
  {language: 'ja', flag: 'jp'},
  {language: 'ko', flag: 'kr'},
  {language: 'ms', flag: 'my'},
  {language: 'ur', flag: 'pk'},
  {language: 'tl', flag: 'ph'},
  {language: 'ar', flag: 'sa'},
  {language: 'en', flag: 'sg'},
  {language: 'zh-tw', flag: 'tw'},
  {language: 'th', flag: 'th'},
  {language: 'tr', flag: 'tr'},
  {language: 'vi', flag: 'vn'},
  {language: 'en', flag: 'ca'},
  {language: 'es', flag: 'mx'},
  {language: 'en', flag: 'us'},
  {language: 'es', flag: 'ar'},
  {language: 'es', flag: 'bo'},
  {language: 'pt', flag: 'br'},
  {language: 'es', flag: 'cl'},
  {language: 'es', flag: 'co'},
  {language: 'es', flag: 'pe'},
];

export interface Flag {
  name: string;
  shortName: string;
  component: FlagComponent;
}

export const defaultFlag: Flag = {
  name: 'USA',
  component: US,
  shortName: 'US',
};

export const FlagContextElement = React.createContext<FlagContext>({
  currentFlag: defaultFlag,
  setFlag: () => {},
  getCurrentLocale: () => 'en',
});

const FlagKey = 'FlagKey';
export const API_REGION = process.env.REACT_APP_API_REGION;

const FlagProvider: React.FC<BasicElement> = ({children}) => {
  const [currentFlag, setCurrentFlag] = useState<Flag>(defaultFlag);
  const [currentLocale, setCurrentLocale] = useState<string>('en');

  const {i18n} = useTranslation();

  const getCurrentFlag = (region: string, country: string) => {
    return allFlags
      .filter((flag) => flag.region === region)[0]
      .countries.filter((flag) => flag.shortName === country)[0];
  };

  const setFlag = (region: string, shortName: string) => {
    setCurrentFlag(getCurrentFlag(region, shortName));

    const localeName = shortName.toLowerCase();
    const altLanguage = flagLanguageTranslator.find(
      (x) => x.flag === localeName,
    );
    i18n.changeLanguage(altLanguage?.language ?? localeName);
    setCurrentLocale(altLanguage?.language ?? localeName);
    localStorage.setItem(
      FlagKey,
      JSON.stringify({
        region,
        shortName,
      }),
    );
  };

  useEffect(() => {
    const prevFlag = localStorage.getItem(FlagKey);

    if (prevFlag) {
      if (API_REGION !== 'RU') {
        const prevData = JSON.parse(prevFlag);
        setFlag(prevData.region, prevData.shortName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentLocale = useCallback(() => {
    return currentLocale;
  }, [currentLocale]);

  return (
    <FlagContextElement.Provider
      value={{
        currentFlag: currentFlag,
        setFlag,
        getCurrentLocale,
      }}
    >
      {children}
    </FlagContextElement.Provider>
  );
};

export const useLocalization = (): FlagContext => {
  const ctx = useContext(FlagContextElement);
  return ctx;
};

export default FlagProvider;
