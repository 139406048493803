import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {TranslationsCreateCustomPageDAO} from '../../../../api/customPage/dao/CustomPageDAO';
import {LocalizationDTO} from '../../../../api/localization/dto/LocalizationDTO';
import {GetGridTemplate} from '../../../editor/BlocksTemplates';
import {EditorGridViewerData} from '../../../editor/elements/grid/GridType';
import ContentEditor from '../../../editor/service/ContentEditor';
import {
  CopyEditorBlocks,
  PasteEditorBlocks,
} from '../../../editor/service/copyPaste/CopyEditorBlocks';
import {EditorBlock} from '../../../editor/types/EditorBlock';
import FormButton from '../../../form/button/FormButton';
import ImageSelectorInput from '../../../form/imageSelector/ImageSelectorInput';
import Input from '../../../form/input/Input';

interface CustomPageTranslationEditorProps
  extends React.HTMLProps<HTMLDivElement> {
  language: LocalizationDTO;
  onFormUpdated: (data: CustomPageTranslation) => void;
  originalTranslation?: TranslationsCreateCustomPageDAO;
}

export interface CustomPageTranslation {
  languageId: string;
  blockId: string;
  title: string;
  thumbnailId?: string;
}

const CustomPageTranslationEditor: React.FC<
  CustomPageTranslationEditorProps
> = ({language, onFormUpdated, originalTranslation, className, ...rest}) => {
  const {
    register,
    formState: {errors},
    getValues,
    setValue,
    watch,
  } = useForm<CustomPageTranslation>({
    defaultValues: {
      ...originalTranslation,
    },
  });

  const [baseBlock, setBaseBlock] = useState<EditorBlock<EditorGridViewerData>>(
    originalTranslation
      ? JSON.parse(originalTranslation.block.rawData)
      : GetGridTemplate(),
  );

  useEffect(() => {
    setBaseBlock(
      originalTranslation
        ? JSON.parse(originalTranslation.block.rawData)
        : GetGridTemplate(),
    );
    setValue('title', originalTranslation?.title ?? '');
  }, [originalTranslation, setValue]);

  const SendUpdate = useCallback(
    (data: CustomPageTranslation) => {
      onFormUpdated({...data});
    },
    [onFormUpdated],
  );

  useEffect(() => {
    const subscription = watch((vals) => {
      SendUpdate({
        languageId: language.id,
        blockId: baseBlock.id,
        title: vals.title || '',
        thumbnailId: vals.thumbnailId,
      });
    });
    return () => subscription.unsubscribe();
  }, [SendUpdate, baseBlock.id, language.id, watch]);

  useEffect(() => {
    SendUpdate({languageId: language.id, blockId: baseBlock.id, title: ''});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className} {...rest}>
      <div className="flex gap-2 mb-2">
        <Input
          label="Title"
          name="title"
          registration={{register, errors}}
          options={{required: 'Required'}}
        />
        <ImageSelectorInput
          label="Thumbnail image"
          name="thumbnailId"
          registration={{register, errors}}
          getValues={getValues}
          onChange={(name, value) => {
            setValue(name, value[0].id);
          }}
          options={{required: 'Required'}}
          maxFiles={1}
        />
        {/* кнопки Copy и Paste: они работают с буфером обмена, а значит можно на одной вкладке нажать copy и вставить на другой.  Вставить можно в другое место на другой странице или другом переводе. При paste он заново всем объектам задает id. Соответственно при paste на старнице у нас может изменится baseBlock.Id . Идея в том что можно откуда угодно куда угодно перенести*/}
        <FormButton onClick={() => CopyEditorBlocks(baseBlock.id)}>
          Copy
        </FormButton>
        <FormButton
          onClick={() => {
            PasteEditorBlocks((data: EditorBlock<EditorGridViewerData>) => {
              setBaseBlock(data);
              const vals = getValues();
              SendUpdate({
                languageId: language.id,
                blockId: data.id,
                title: vals.title,
              });
            });
          }}
        >
          Paste
        </FormButton>
      </div>
      <ContentEditor coreBlock={baseBlock} />
    </div>
  );
};

export default CustomPageTranslationEditor;
