/* eslint-disable @typescript-eslint/no-explicit-any */
import {queryClient} from '../../../../app/App';
import {EditorBlocks} from '../../AllBlocks';
import {EditorButtonData} from '../../elements/button/ButtonTypes';
import {EditorGridViewerData, GridColumn} from '../../elements/grid/GridType';
import {EditorHeaderData} from '../../elements/header/HeaderTypes';
import {EditorParagraphData} from '../../elements/paragraph/ParagraphType';
import {EditorMultiAnswersData} from '../../elements/questions/multiAnswers/MultiAnswersTypes';
import {EditorBlock, EditorBlockData} from '../../types/EditorBlock';
import {QueryKey} from '@tanstack/react-query';

const prepareHtmlTranslationString = (request: TranslationKey[]): string => {
  return request
    .map((x) => `<element id='${x.key}'>${x.value}</element>`)
    .join();
};

const parseHtmlTranslationString = (response: string): TranslationKey[] => {
  return [
    ...response.matchAll(/\<element id=.(.+?).\>(.+?)\<\/element\>/g),
  ].map((translation) => {
    if (translation.length !== 3)
      throw new Error('Translation parsing error! Incorrect input');

    return {
      key: translation[1],
      value: translation[2],
    };
  });
};

export const SaveBlocks = (
  coreblockId: string,
): EditorBlock<any> | undefined => {
  const allBlocks = queryClient.getQueriesData<EditorBlock<any>>(EditorBlocks);
  const sourceBlock = allBlocks.find((x) => x[1].id === coreblockId);

  const findElement = (id: string): EditorBlock<any> => {
    const block = allBlocks.find((x) => x[1].id === id);
    if (!block)
      throw new Error(`Save error! Block with id '${id}' is not found!`);

    return block[1];
  };

  const getObject = (block: EditorBlock<any>): EditorBlock<any> => {
    const baseObject: EditorBlock<any> = block;
    if (block.type === 'grid') {
      const typedBase = baseObject as EditorBlock<EditorGridViewerData>;
      const updatedColumns: GridColumn[] = [];
      for (let i = 0; i < typedBase.data.columns.length; i += 1) {
        const columnObjects: EditorBlock<EditorBlockData>[] = [];
        for (let j = 0; j < typedBase.data.columns[i].inner.length; j += 1) {
          const cachedBlock = findElement(
            typedBase.data.columns[i].inner[j].id,
          );
          const columnElemnt = getObject(cachedBlock);
          columnObjects.push(columnElemnt);
        }
        updatedColumns.push({
          ...typedBase.data.columns[i],
          inner: columnObjects,
        });
      }

      return {
        ...typedBase,
        data: {
          ...typedBase.data,
          columns: updatedColumns,
        },
      };
    }

    return baseObject;
  };

  if (sourceBlock) {
    const t = getObject(sourceBlock[1]);
    return t;
  }

  return undefined;
};

export type TranslationKey = {key: string; value: string};
export const PrepareTranslationKeys = (
  coreblockId: string,
): TranslationKey[] => {
  const allBlocks = queryClient.getQueriesData<EditorBlock<any>>(EditorBlocks);
  const sourceBlock = allBlocks.find((x) => x[1].id === coreblockId);

  const findElement = (id: string): EditorBlock<any> => {
    const block = allBlocks.find((x) => x[1].id === id);
    if (!block)
      throw new Error(`Save error! Block with id '${id}' is not found!`);

    return block[1];
  };

  const fullKeyList: TranslationKey[] = [];

  const getObject = (block: EditorBlock<any>): EditorBlock<any> => {
    const baseObject: EditorBlock<any> = block;
    if (block.type === 'grid') {
      const typedBase = baseObject as EditorBlock<EditorGridViewerData>;
      const updatedColumns: GridColumn[] = [];
      for (let i = 0; i < typedBase.data.columns.length; i += 1) {
        const columnObjects: EditorBlock<EditorBlockData>[] = [];
        for (let j = 0; j < typedBase.data.columns[i].inner.length; j += 1) {
          const cachedBlock = findElement(
            typedBase.data.columns[i].inner[j].id,
          );
          const columnElemnt = getObject(cachedBlock);
          columnObjects.push(columnElemnt);
        }
        updatedColumns.push({
          ...typedBase.data.columns[i],
          inner: columnObjects,
        });
      }

      return {
        ...typedBase,
        data: {
          ...typedBase.data,
          columns: updatedColumns,
        },
      };
    }

    switch (block.type) {
      case 'paragraph': {
        const element = baseObject as EditorBlock<EditorParagraphData>;
        if (element.data.isProhibitTranslation !== true)
          fullKeyList.push({
            key: element.id,
            value: element.data.paragraph,
          });
        break;
      }

      case 'header': {
        const element = baseObject as EditorBlock<EditorHeaderData>;
        fullKeyList.push({
          key: element.id,
          value: element.data.text,
        });
        break;
      }

      case 'button': {
        const element = baseObject as EditorBlock<EditorButtonData>;
        fullKeyList.push({
          key: element.id,
          value: element.data.label,
        });
        break;
      }
      case 'multiAnswers': {
        const element = baseObject as EditorBlock<EditorMultiAnswersData>;
        const answers = element.data.answers.map((x) => ({
          key: x.id,
          value: x.answer,
        }));

        fullKeyList.push({
          key: element.id,
          value: prepareHtmlTranslationString(answers),
        });
        break;
      }
    }

    return baseObject;
  };

  if (sourceBlock) {
    getObject(sourceBlock[1]);
    return fullKeyList;
  }

  return [];
};

export const ApplyTranslationKeys = (
  coreblockId: string,
  translations: TranslationKey[],
): EditorBlock<any> | undefined => {
  const allBlocks = JSON.parse(
    JSON.stringify(queryClient.getQueriesData<EditorBlock<any>>(EditorBlocks)),
  ) as [QueryKey, EditorBlock<any>][];
  const sourceBlock = allBlocks.find((x) => x[1].id === coreblockId);

  const findElement = (id: string): EditorBlock<any> => {
    const block = allBlocks.find((x) => x[1].id === id);
    if (!block)
      throw new Error(`Save error! Block with id '${id}' is not found!`);

    return block[1];
  };

  const getObject = (block: EditorBlock<any>): EditorBlock<any> => {
    const baseObject: EditorBlock<any> = block;
    if (block.type === 'grid') {
      const typedBase = baseObject as EditorBlock<EditorGridViewerData>;
      const updatedColumns: GridColumn[] = [];
      for (let i = 0; i < typedBase.data.columns.length; i += 1) {
        const columnObjects: EditorBlock<EditorBlockData>[] = [];
        for (let j = 0; j < typedBase.data.columns[i].inner.length; j += 1) {
          const cachedBlock = findElement(
            typedBase.data.columns[i].inner[j].id,
          );
          const columnElemnt = getObject(cachedBlock);
          columnObjects.push(columnElemnt);
        }
        updatedColumns.push({
          ...typedBase.data.columns[i],
          inner: columnObjects,
        });
      }

      return {
        ...typedBase,
        data: {
          ...typedBase.data,
          columns: updatedColumns,
        },
      };
    }

    const translation = translations.find((x) => x.key === block.id)?.value;

    if (translation) {
      switch (block.type) {
        case 'paragraph': {
          const element = baseObject as EditorBlock<EditorParagraphData>;
          element.data.paragraph = translation;
          return element;
        }

        case 'header': {
          const element = baseObject as EditorBlock<EditorHeaderData>;
          element.data.text = translation;
          return element;
        }
        case 'button': {
          const element = baseObject as EditorBlock<EditorButtonData>;
          element.data.href = translation;
          return element;
        }
        case 'multiAnswers': {
          const element = baseObject as EditorBlock<EditorMultiAnswersData>;
          const results = parseHtmlTranslationString(translation);
          element.data.answers.forEach((answer) => ({
            ...answer,
            answer:
              results.find((x) => x.key === answer.id)?.value ?? answer.answer,
          }));

          return element;
        }
      }
    }

    return {...baseObject};
  };

  if (sourceBlock) {
    return getObject(sourceBlock[1]);
  }

  return undefined;
};
