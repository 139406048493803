import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {ReactNotifications} from 'react-notifications-component';
import ReactGA from 'react-ga4';
import AppRouter from './router/Router';
import 'react-notifications-component/dist/theme.css';
import AuthProvider from '../services/auth/AuthService';
import FlagProvider from '../services/flagProvider/FlagProvider';
import ConfirmationAlertProvider from '../services/confirmationAlert/ConfirmationAlertService';
import {Suspense} from 'react';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

export const APP_GA_TAG = process.env.REACT_APP_GA_TAG;
ReactGA.initialize(APP_GA_TAG || '');

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactNotifications />

      <AuthProvider>
        <ConfirmationAlertProvider>
          <FlagProvider>
            <Suspense>
              <AppRouter />
            </Suspense>
          </FlagProvider>
        </ConfirmationAlertProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
export default App;
